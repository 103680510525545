<template>
  <div>
    <div class="filter-panel">
      <input
          v-model="queryRooms.roomNo"
          class="cs-input"
          placeholder="搜索房间号"
          style="border-radius: 4px"
          type="text"
      />


      <CSSelect style="margin-right: 20px">
        <select v-model="queryRooms.state">
          <option value="">房间状态不限</option>
          <option
              v-for="(building,index) in buildingsStart"
              :key="building"
              :value="index + 1"
          >
            {{ building }}
          </option>
        </select>
      </CSSelect>


      <CSSelect style="margin-right: 20px">
        <select v-model="queryRooms.buildingId" @change="changeBuilding">
          <option value="">全部楼栋</option>
          <option
              v-for="building in buildings"
              :key="building.code"
              :value="building.code"
          >
            {{ building.name }}
          </option>
        </select>
      </CSSelect>
      <CSSelect style="margin-right: 30px">
        <select v-model="queryRooms.floor">
          <option value="">楼层不限</option>
          <option v-for="floor in floorSpace" :key="floor" :value="floor">
            {{ floor }}
          </option>
        </select>
      </CSSelect>
      <CSSelect style="margin-right: 30px">
        <select v-model="queryRooms.isDivision">
          <option value="">是否分割不限</option>
          <option v-for="item in isDivisionList" :key="item.value" :value="item.id">
            {{ item.value }}
          </option>
        </select>
      </CSSelect>
      <button
          class="btn btn-primary btn-sm"
          type="button"
          v-on:click="queryRoom()"
      >
        查询
      </button>
    </div>

    <div class="result-panel">
      <CSTable :thead-top="filterHeight">
        <template
            v-if="
            DEPARTMENT_TYPES.OPERATIONS === +accountInfo.dutyType ||
            DEPARTMENT_TYPES.DIRECTOR === +accountInfo.dutyType
          "
            v-slot:header
        >
          <div class="table-header-panel text-right">
            <button
                class="btn btn-primary sticky-right"
                v-on:click="_openEditRoomModel()"
            >
              <svg aria-hidden="true" class="icon">
                <use xlink:href="#icon-menujiahao"></use>
              </svg>
              添加房间
            </button>
          </div>
        </template>
        <template v-slot:thead>
          <tr>
            <th>添加时间</th>
            <th>房间号</th>
            <th>房间状态</th>
            <th>所属楼栋楼层</th>
            <th>分割房间</th>
            <th>建筑面积<span>(㎡)</span></th>
            <th>租赁单价<span>(元/㎡/天)</span></th>
            <th>水表/电表</th>
            <th>水电公摊承担方</th>
            <th>业主</th>
            <th>租客</th>
            <th>创建人</th>
            <th>操作</th>
          </tr>
        </template>
        <template v-slot:tbody>
          <tr v-for="room in roomList" :key="room.roomId">
            <td class="date-td">{{ room.createTime || "-" }}</td>
            <td>
              {{ room.roomNo || "-" }}
            </td>
            <td>{{ roomState[room.state] || "-" }}</td>
            <td>
              {{ room.buildingName + '-' + room.floor || "-" }}
            </td>
            <td>
              <span v-if="room.divisionNum > 0&&room.isDivision != 1"
                    class="btn-active"
                    @click="getDivision(room)">
                {{ room.divisionNum }}个房间
              </span>
              <span v-else>
                                 -
                            </span>

            </td>
            <td>
              {{ room.space || "-" }}
            </td>
            <td style="min-width: 140px">
              <template v-if="room.price">
                {{ room.price / 100 }}
              </template>
              <template v-else>-</template>
            </td>
            <td>
              <span
                  v-if="room.waterCount || room.electricityCount"
                  class="btn-active"
                  @click="getWater(room.waterMeter,room.electricityMeter)"
              >{{ (room.waterCount || 0) + (room.electricityCount || 0) }}个</span
              >
              <span v-else>-</span>
            </td>
            <td>
                          <span v-if="room.waterAndElectricity === 0">
                            {{ company || '-' }}
                          </span>
              <span v-else-if="room.waterAndElectricity === 1">
                            暂不确定
                          </span>
              <span v-else-if="room.waterAndElectricity === 2">
                            {{ room.hydropowerCompanyName }}
                          </span>
              <span v-else>
                            -
                          </span>
            </td>
            <td>{{ room.ownerName ? room.ownerName : "-" }}</td>
            <td>
              <template v-if="room.lesseeCompany">
                <span class="allow-click" @click="lookTenant(room)">查看</span>
              </template>
              <template v-else-if="room.relevanceLesseeName">
                <span class="allow-click" @click="lookTenant(room)">查看</span>
              </template>
              <template v-else>-</template>
            </td>
            <!--                            <td>
                                          {{ getBinderName(room.ownerRef) }}
                                      </td>-->

            <td>{{ room.createUser || "-" }}</td>
            <td>
              <div v-if="room.state !== 3" class="btn-group">
                <button
                    aria-expanded="false"
                    aria-haspopup="true"
                    class="btn btn-primary dropdown-toggle"
                    data-toggle="dropdown"
                    type="button"
                >
                  操作
                </button>
                <ul class="dropdown-menu" style="width: 76px">
                  <li
                      v-if="room.state === 2"
                      @click="changeRoomState(room.roomId, 1)"
                  >
                    <a style="width: 100%">启用</a>
                  </li>
                  <li
                      v-if="room.state === 1"
                      @click="changeRoomState(room.roomId, 2)"
                  >
                    <a style="width: 100%">禁用</a>
                  </li>
                  <li v-show="room.invalid === false" @click="changeRoomState(room.roomId, 3)">
                    <a style="width: 100%">作废</a>
                  </li>
                  <li @click="upadteRoomInfo(room)">
                    <a style="width: 100%">修改房间</a>
                  </li>
                  <li @click="upadteRoomState(room)">
                    <a style="width: 100%">修改水电表</a>
                  </li>

                </ul>
              </div>
              <template v-else>-</template>
            </td>
          </tr>
        </template>
      </CSTable>
      <Pagination component="pagination" name="pagination"></Pagination>
    </div>

    <!-- 修改员工权限 -->
    <EditRoom key="room" :totalName='true' componentName="EditRoom" name="editRoom"></EditRoom>
    <!-- 修改水电表 -->
    <EditHydroprwer
        componentName="EditHydroprwer"
        name="editHydroprwer"

    ></EditHydroprwer>
    <!-- 水电表信息弹出框 -->
    <GetHydropower
        componentName="GetHydropower"
        name="getHydropower"
    ></GetHydropower>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination.vue";
import EditRoom from "@/components/EditRoom.vue";
import CSSelect from "@/components/common/CSSelect";
import GetHydropower from "@/components/GetHydropower";
import EditHydroprwer from "@/components/EditHydroprwer.vue";
import {
  changeRoomStateUrl,
  queryBuildingUrl,
  queryRoomRentUrl,
  queryRoomRentUrls,
  querySharedConfigUrl,
} from "@/requestUrl";
import {DEPARTMENT_TYPES} from "@/constant";
import CSTable from "@/components/common/CSTable";

export default {
  name: "room",
  props: {},
  created() {
    window.addEventListener("keydown", this._queryRoomMethodDown);
    this.getQuerySharedConfig();
  },
  beforeDestroy() {
    window.addEventListener("keydown", this._queryRoomMethodDown);
  },
  components: {
    CSTable,
    Pagination,
    EditRoom,
    CSSelect,
    GetHydropower,
    EditHydroprwer,
  },
  data() {
    return {
      xx: '',
      buildingsStart: ['启用', '禁用', '作废'],
      company: '',
      isDivisionList: [
        {
          id: 1,
          value: '不分割'
        },
        {
          id: 0,
          value: '分割'
        },
      ],
      roomState: {
        1: "启用",
        2: "禁用",
        3: "作废",
      },
      filterHeight: 0,
      queryRooms: {
        buildingId: "",
        roomNo: "",
        state: "",
        floor: "",
        isDivision: ""
      },
      DEPARTMENT_TYPES,
      accountInfo: this.$vc.getCurrentStaffInfo(),
      roomUnits: [],
      buildings: [],
      listRoomData: [],
      roomList: [],
      floorSpace: [],
      pageParams: {
        pageNo: 1,
        pageSize: 10
      }
    };
  },
  mounted() {
    this.queryRoom();
    //根据 参数查询相应数据
    this.getBuildings();
    this.$vc.on("room", "listRoom", (_param) => {
      this.queryRoom();
    });
    this.$vc.on("room", "loadData", (_param) => {
      this.queryRoom();
    });
    this.$vc.on(
        this.$route.path,
        "pagination",
        "page_event",
        (_currentPage) => {
          this.pageParams.pageNo = _currentPage;
          this.queryRoom(_currentPage);
        }
    );
    this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
    window.addEventListener("resize", () => {
      this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
    });
  },
  updated() {
    this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
  },
  methods: {
    //查询水电费公摊设置
    getQuerySharedConfig() {
      this.$fly.get(querySharedConfigUrl, {
        regionCode: this.$vc.getCurrentRegion().code
      }).then(res => {
        if (res.code != 0) {
          return
        }
        console.log('查询设置');
        console.log(res.data);
        this.company = res.data.equallySharedCompanyName || "";
      })
    },
    upadteRoomInfo(room) {
      console.log('修改房间');
      this.$vc.emit("editRoom", "openEditRoomModal", {
        ...room,
        price: room.price,
        title: '修改房间'

      });
    },
    _queryRoomMethodDown(e) {
      if (e.keyCode == 13) {
        this.queryRoom();
      }
    },
    //修改房间
    upadteRoomState(room) {
      this.$vc.emit("editHydroprwer", "openEditHydroprwer", {
        title: "修改水电表",
        room,
      });
    },
    /**
     * 查看租客
     * @param {Object} roomInfo 租客信息
     * */
    lookTenant(roomInfo) {
      this.$CSDialog.alert({
        width: "558px",
        title: "租客",
        messageHtml: `
                    <div class="comment-panel" style="--item-label: 160px;">
                        <div class="item right">
                            <div class="item-label">企业/个人名称</div>
                            <div>${roomInfo.lesseeCompany || roomInfo.relevanceLesseeName}</div>
                        </div>
                        <div class="item right">
                            <div class="item-label">合同时间</div>
                            <div>${roomInfo.contractDuration || '-'}</div>
                        </div>
                        <div class="item right">
                            <div class="item-label">对接人</div>
                            <div>${roomInfo.lesseeName}</div>
                        </div>
                        <div class="item right">
                            <div class="item-label">手机号</div>
                            <div>${roomInfo.lesseePhone}</div>
                        </div>
                    </div>
                `,
      });
    },
    /**
     * 修改房间状态
     * @param {Number} id 房间id
     * @param {Number} state 房间状态 1:启用 2:禁用 3:作废
     * */
    changeRoomState(id, state) {
      this.$CSDialog.confirm({
        title: "提示",
        message: `确定${this.roomState[state]}吗?`,
        onConfirm: () => {
          this.$fly
              .post(changeRoomStateUrl, {
                id,
                state,
              })
              .then((res) => {
                if (res.code !== 0) {
                  return;
                }
                this.$vc.toast(`${this.roomState[state]}成功`);
                this.$CSDialog.instance.closeDialog();
                this.listRoom();
              });
        },
      });
    },
    /**
     * 修改选中的楼栋
     * @param {Object} e
     * */
    changeBuilding(e) {
      if (e.target.selectedIndex > 0) {
        const building = this.buildings[e.target.selectedIndex - 1];
        let floorSpace = [];
        for (
            let i = -(building.undergroundFloor || 0);
            i <= building.aboveGroundFloor;
            i++
        ) {
          if (i !== 0) {
            floorSpace.push(i);
          }
        }
        this.floorSpace = floorSpace;
        return;
      }
      this.floorSpace = [];
    },
    //获取水表信息
    getWater(tiem, item2) {
      let times = tiem || [];
      times = times.map(item => {
        return {
          name: '水表',
          value: item
        }
      })
      let item3 = item2 || [];
      item3 = item3.map(item => {
        return {
          name: '电表',
          value: item
        }
      })
      let params = times.concat(item3);
      console.log(params);
      this.$vc.emit("getHydropower", "number", {title: "水表/电表", tiem: params});
    },
    // //获取电表信息
    // getElectric(tiem) {
    //     this.$vc.emit("getHydropower", "number", {title: "电表信息", tiem});
    // },
    // 跳转到众创空间页面 - 只定义方法，暂未实现
    getDivision(room) {
      this.$router.push({
        name: 'roomInfoPrivate',
        params: room
      })
    },
    // 获取楼栋列表
    getBuildings() {
      this.$fly
          .post(queryBuildingUrl, {
            id: "",
            code: "",
            regionCode: this.$vc.getCurrentRegion().code,
            name: "",
          })
          .then((res) => {
            this.buildings = res.data.datas;
          });
    },
    getBinderName(binderInfo) {
      if (binderInfo == null) {
        return "-";
      }
      return binderInfo.isEnterprise == 1
          ? binderInfo.enterpriseName
          : binderInfo.name + ",  " + binderInfo.link;
    },
    listRoom: function () {
      return this.$fly
          .post(queryRoomRentUrl, {
            regionId: this.$vc.getCurrentRegion().communityId,
            ...this.queryRooms,
          })
          .then((res) => {
            let listRoomData = [];

            res.data.datas.forEach((building) => {
              building.floors.forEach((floor) => {
                floor.rooms.forEach((room) => {
                  listRoomData.push({
                    floor: floor.floor,
                    buildingId: building.buildingId,
                    buildingName: building.buildingName,
                    ...room,
                  });
                });
              });
            });
            listRoomData.sort((preVal, val) => {
              const preTime = preVal.createTime
                      .replace(/-/g, "")
                      .replace(/:/g, ""),
                  currentTime = val.createTime.replace(/-/g, "").replace(/:/g, "");
              return currentTime - preTime;
            });
            this.listRoomData = listRoomData;
            return listRoomData;
          })
          .catch(() => []);
    },
    async queryRoom(pageNo = this.pageParams.pageNo, pageSize = 10) {
      this.$fly.post(queryRoomRentUrls, {
        regionId: this.$vc.getCurrentRegion().communityId,
        ...this.queryRooms,
        pageNo,
        pageSize
      }).then(res => {
        if (res.code != 0) {
          return
        }
        this.roomList = res.data.datas;
        if (pageNo === 1) {
          this.$vc.emit(this.$route.path, "pagination", "init", {
            total: res.data.total,
            currentPage: pageNo,
            pageSize,
          });
        }
      })
    },

    /**
     * 添加、修改房间
     * @param {Object | Null} room 房间信息 如果为空，则是添加房间
     *  */
    _openEditRoomModel: function (room) {
      this.$vc.emit("editRoom", "openEditRoomModal", room);
    },
  }
  ,
}
;
</script>
<style lang="stylus" scoped>
.input-group {
  width: 144px;
}

.cs-btn {
  width: 50px;

  &.btn-white {
    border-color: #1ab394;
    color: #1ab394;
  }
}

.result-panel table.table td, .result-panel table.table th {
  vertical-align: middle;

  p {
    margin: 0;

    &:not(:last-of-type) {
      margin-bottom: 15px;
    }
  }
}

.btn-active {
  color: #1dafff;
  text-decoration: underline;
  cursor: pointer;
}
</style>
